import P from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap/esm";
import { Fragment, useContext, useEffect, useState } from "react";
import ReservatorioCard from "./module/reservatorios/ReservatorioCard";
import { Ctx } from "../store/Context/Ctx";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../module/api";
import TextoInfo from "./module/TextoInfo";

const ReservatorioSelected = ({ setor, darkmode }) => {
	const [reservatorios, setReservatiorios] = useState([]);
	const navigate = useNavigate();

	const { params, value } = useParams();

	useEffect(() => {
		if (
			reservatorios.length === 0 &&
			params !== undefined &&
			value !== undefined
		) {
			async function getReservatorios() {
				await api
					.get(`/reservatorios`, {
						params: {
							"populate[0]": "setor",
							"populate[1]": "setor.distrito",
							"populate[2]": "equipamento",
							"populate[3]": "equipamento.connection",
							"populate[4]": "reservatorio_img",
							"filters[setor][id][$eq]": parseInt(value),
						},
					})
					.then((r) => setReservatiorios(r.data.data))
					.catch((e) => console.error(e));
			}
			getReservatorios();
		}
	}, [params, value]);

	if (reservatorios.length === 0) {
		return (
			<Container className="">
				<Row>
					<Col>
						<div className="title-desc">
							Saiba como está o abastecimento de água no seu bairro. Mas
							lembre-se, a sua região pode ser abastecida por mais de um
							reservatório, já que eles são interligados para evitar
							interrupções momentâneas.
						</div>
					</Col>
				</Row>
				<Row className="align-items-center">
					<Col
						md={12}
						style={{
							display: "flex",
							justifyContent: "space-between",
							flexWrap: "wrap",
						}}
						className="mb-3 "
					>
						<h4 style={{ marginTop: "32px" }}>
							Região: {setor?.attributes?.setor_nome}{" "}
						</h4>{" "}
						<button
							className="btn btn-outline-info"
							onClick={() => navigate("/search")}
						>
							<i className="fa fa-arrow-rotate-left"></i> Voltar
						</button>
					</Col>
					<Col
						mc={12}
						style={{
							outline: "dashed thin #d4d4d4",
							padding: "4px 12px",
						}}
					>
						Bairros Atendidos:{" "}
						{setor?.attributes?.distritos?.data?.map((d) => {
							return <span key={d.id}>{d.attributes.dmc_nome},</span>;
						})}
					</Col>
				</Row>{" "}
				<Row className="align-items-center my-5">
					<Col md={12}>
						<h4>Nenhum reservatório encontrado para esta Região!</h4>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<Container className="mb-5">
			<TextoInfo />
			<Row className="align-items-center">
				<Col
					md={12}
					style={{
						display: "flex",
						justifyContent: "space-between",
						flexWrap: "wrap",
					}}
					className="mb-3"
				>
					<h4>Região: {setor?.attributes?.setor_nome} </h4>{" "}
					<button
						className="btn btn-outline-info"
						onClick={() => navigate("/search")}
					>
						<i className="fa fa-arrow-rotate-left"></i> Voltar
					</button>
				</Col>
				<Col
					mc={12}
					style={{ outline: "dashed thin #d4d4d4", padding: "4px 12px" }}
				>
					Bairros Atendidos:{" "}
					{setor?.attributes?.distritos?.data?.map((d) => {
						return <span key={d.id}>{d.attributes.dmc_nome},</span>;
					})}
				</Col>
			</Row>

			{reservatorios.length &&
				reservatorios.map((reservatorio) => {
					return (
						<Fragment key={reservatorio.id}>
							<ReservatorioCard
								reservatorio={reservatorio}
								darkmode={darkmode ? true : false}
							/>
						</Fragment>
					);
				})}
		</Container>
	);
};

export default ReservatorioSelected;
ReservatorioSelected.propTypes = {
	distrito: P.oneOfType([P.object, P.array]),
	setVoltar: P.func,
	darkmode: P.bool,
};
