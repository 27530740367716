import React from "react";
import P from "prop-types";
const Ecometria = (props) => {
	const openInNewTab = (url) => {
		window.open(url, "_blank", "noreferrer");
	};
	const styles = {
		positions: {
			position: props.position,
			top: props.top,
			bottom: props.bottom,
			left: props.left,
			right: props.right,
			width: props.width,
			height: props.height,
			alignItems: "center",
			justifyContent: "center",
			display: "flex",
			cursor: "pointer",
			backgroundColor: "#fff",
			padding: "10px 0 30px 0",
		},
	};
	return (
		<div style={styles.positions}>
			<span className="text-muted" style={{ fontSize: "12px" }}>
				Desenvolvido por{" "}
			</span>

			<img
				src="/assets/logo/ecometria-logo.jpg"
				alt="SAEMAS"
				target="_blank"
				rel="noreferrer"
				width={100}
				onClick={() => openInNewTab("https://www.ecometria.eco.br")}
			/>
		</div>
	);
};
Ecometria.propTypes = {
	position: P.string,
	top: P.oneOfType([P.number, P.string]),
	bottom: P.oneOfType([P.number, P.string]),
	left: P.oneOfType([P.number, P.string]),
	right: P.oneOfType([P.number, P.string]),
	width: P.oneOfType([P.number, P.string]),
	height: P.oneOfType([P.number, P.string]),
};
export default Ecometria;
