import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_TOKEN;
const apiNodeUrl = `${process.env.REACT_APP_SERVER_API_PROTOCOL}://${
	process.env.REACT_APP_SERVER_SOCKET_HOST
}${
	process.env.REACT_APP_SERVER_SOCKET_PORT
		? `:${process.env.REACT_APP_SERVER_SOCKET_PORT}`
		: ""
}`;

const accessToken = Cookies.get("io");
// Configurando uma instância do Axios com axios.create
export const api = axios.create({
	baseURL: apiUrl + "/api",
	timeout: 5000, // Tempo limite para a requisição em milissegundos
	headers: {
		"Content-Type": "application/json", // Exemplo de cabeçalho de conteúdo
		Authorization: `Bearer ${apiKey}`, // Exemplo de cabeçalho de token
	},
});
export const apiNode = axios.create({
	baseURL: apiNodeUrl,
	headers: {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json", // Pode ser necessário ajustar o Content-Type conforme necessário
	},
});
