import React, { Suspense, lazy } from "react";

import { useParams } from "react-router-dom";
import { PageContext } from "../Context/PageContext";
const Home = lazy(() => import("../../page/Home"));

const Search = lazy(() => import("../../page/Search"));
const Setor = lazy(() => import("../../page/SetorId"));
const Reservatorios = lazy(() => import("../../page/ReservatoriosAll"));
const pageMap = {
	search: Search,

	setor: Setor,
	reservatorios: Reservatorios,
};
const PageProvider = ({ children }) => {
	const params = useParams();

	const ComponentPage = (params.slug && pageMap[params.slug]) || Home;

	return (
		<PageContext.Provider
			value={
				<Suspense fallback={"Carregando..."}>
					<ComponentPage data={{ data: [] }} />
				</Suspense>
			}
		>
			{children}
		</PageContext.Provider>
	);
};

export default PageProvider;
