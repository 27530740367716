import React from "react";
import { Row, Col } from "react-bootstrap";

const TextoInfo = () => {
	return (
		<Row className="mb-4 p-4 border">
			<Col className="">
				<div className="text-center">
					Saiba como está o abastecimento de água no seu bairro. Mas lembre-se,
					a sua região pode ser abastecida por mais de um reservatório, já que
					eles são interligados para evitar interrupções momentâneas.
				</div>
			</Col>
		</Row>
	);
};

export default TextoInfo;
