import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { GetCookies } from "../helpers/helpers";
import Cookies from "js-cookie";

export const useSocket = () => {
	const SOCKET_SERVER_URL = `${process.env.REACT_APP_SERVER_API_PROTOCOL}://${
		process.env.REACT_APP_SERVER_SOCKET_HOST
	}${
		process.env.REACT_APP_SERVER_SOCKET_PORT
			? `:${process.env.REACT_APP_SERVER_SOCKET_PORT}`
			: ""
	}`;
	const tokenning = Cookies.get("io");
	const [socketInstance, setInstance] = useState([]);
	const [isConnected, setIsConnected] = useState(socketInstance?.connected);
const [token, setToken] = useState(tokenning);

useEffect(() => {
	if(tokenning){

setToken(tokenning)
	}
},[tokenning])
	useEffect(() => {
		if ((token && socketInstance.length === 0) || (token && !isConnected))
			setInstance(
				io(SOCKET_SERVER_URL, {
					auth: { token },
					reconnection: true,
					reconnectionAttempts: 10,
					reconnectionDelay: 1000,
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, isConnected]);

	useEffect(() => {
		const handleConnect = () => {
			setIsConnected(true);
			console.log("connected");
		};

		const handleDisconnect = () => {
			setIsConnected(false);
		};
		if (socketInstance.length !== 0) {
			socketInstance.on("connect", handleConnect);
			socketInstance.on("reconnect", handleConnect); // Adiciona listener para reconectar
			socketInstance.on("disconnect", handleDisconnect);

			return () => {
				socketInstance.off("connect", handleConnect);
				socketInstance.off("reconnect", handleConnect);
				socketInstance.off("disconnect", handleDisconnect);
				socketInstance.emit("disconnectSocket");
			};
		}
	}, [socketInstance]);
	if(tokenning === undefined){return false}
	return { socketInstance, isConnected, token };
};
