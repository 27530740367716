
import { apiNode } from "../../../../module/api";

export async function GetHistoricos({
	dbname,
	tag_id,
	table,
	connection,
	filters,
	order,
	limit,
token
}) {
	if(!dbname || !tag_id || !table || !token){
		return false
	}
	const options = {
		method: "GET",
		url: `/api/historicos`,
		headers:{
			Authorization: `Bearer ${token}`,
		},
		params: {
			dbname,
			table,
			// where: `dataPointId = (SELECT id  FROM saemas2.dataPoints WHERE xid = 'DP_P22_0')`,
			where: `dataPointId = (SELECT id FROM dataPoints WHERE xid = '${tag_id}') ${
				(filters && `AND ${filters}`) || ""
			}`,
			limit,
			order: order || "",
			connection,
		},
	};
	const response = await apiNode.request(options);

	try {
		if (response) {
			return response.data;
		}
	} catch (error) {
		throw new Error({ error: error.message });
	}
	// return response.data;
}
