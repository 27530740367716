import Layout from "./Layout";

const routes = [
	{
		path: "/",
		element: <Layout />,
	},
	{
		path: "/:slug",
		element: <Layout />,
	},
	{
		path: ":slug/:params/:value?",
		element: <Layout />,
	},
];

export default routes;
