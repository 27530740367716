import { useEffect, useState } from "react";
import P from "prop-types";
import { Ctx } from "../Context/Ctx";
import { GetCookies, LoginSocket } from "../../helpers/helpers";
import { AuthContext } from "../Context/AuthContext";

const AuthProvider = ({ children }) => {
	const tokenApi = GetCookies("io");
	const[socketAuthenticated, setSocketAuthenticated] = useState(false)
	const [socketToken, setSocketToken] = useState([])


	useEffect(() => {
		if (!tokenApi && !socketAuthenticated && socketToken.length === 0) {
			async function getLoginSocket(){
		const response = await LoginSocket();
	
		setSocketAuthenticated(response.isAuthenticate)
		setSocketToken(response.jwt)
	}
	getLoginSocket()
		}
	},[]);

	useEffect(() => {
		if(tokenApi){
			setSocketAuthenticated(true)
		}
	},[tokenApi])
	if(!socketAuthenticated){
		return;
	}
	return <AuthContext.Provider value={{ data:{socketAuthenticated,socketToken} }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
AuthProvider.propTypes = {
	children: P.node.isRequired,
};
