import Cookies from "js-cookie";
import { api } from "../module/api";
const SOCKET_SERVER_URL = `${process.env.REACT_APP_SERVER_API_PROTOCOL}://${
	process.env.REACT_APP_SERVER_SOCKET_HOST
}${
	process.env.REACT_APP_SERVER_SOCKET_PORT
		? `:${process.env.REACT_APP_SERVER_SOCKET_PORT}`
		: ""
}`;
/**Get Cookies */
export function GetCookies(name) {
	const cookies = Cookies.get(name);

	if (!cookies) {
		return false;
	}
	return cookies;
}

export const LoginSocket = async () => {
	const secret = process.env.REACT_APP_SERVER_SECRET_KEY;
	const response = await api
		.post(`${SOCKET_SERVER_URL}/api/login`, {
			token: secret,
		})

		.catch((e) => console.log(e));

	const data = response.data;

	try {
		if (data.token) {
			Cookies.set("io", data.token, { sameSite: "None", secure: true });
			console.log("Conectado ao socket io com sucesso!");
			return { isAuthenticate: true, jwt: data.token };
		} else {
			return { isAuthenticate: false };
		}
	} catch (error) {
		console.error("Erro ao Conectar socket io ", error);
	}
};
