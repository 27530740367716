import React from "react";

import PageProvider from "./store/providers/PageProvider";
import Page from "./page/Page";
import ReservatorioProvider from "./store/providers/ReservatorioProvider";
const Layout = (props) => {
	return (
		<PageProvider>
			<ReservatorioProvider>
				<div className="pt-4">
					{(props.error === 404 && props.children) || <Page />}
				</div>
			</ReservatorioProvider>
		</PageProvider>
	);
};

export default Layout;
