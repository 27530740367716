import P from "prop-types";
import { Ctx } from "../Context/Ctx";
import React, { useEffect, useState } from "react";
import { api } from "../../module/api";
const ReservatorioProvider = ({ children }) => {
	const [distritoList, setDistritoList] = useState([]);
	const [distritoMeta, setDistritoMeta] = useState([]);
	const [dataSearch, setDataSearch] = useState([]);
	const [loader, setLoader] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [distritoSelected, setDistritoSelected] = useState(false);
	const [ReservatoriosAllList, setReservatoriosAllList] = useState([]);

	useEffect(() => {
		if (ReservatoriosAllList.length === 0) {
			async function getReservatorioList() {
				await api
					.get("/reservatorios", {
						params: {
							"populate[0]": "setor",
							"populate[1]": "setor.distritos",
							"populate[2]": "equipamento",
							"populate[3]": "equipamento.connection",
							"populate[4]": "reservatorio_img",
							"filters[setor][abastecimento][$eq]": true,
							sort: "nome:asc",
						},
					})
					.then((r) => setReservatoriosAllList(r.data.data))
					.catch((e) => console.error(e))
					.finally(() => setLoader(false));
			}
			getReservatorioList();
		}
	}, []);

	useEffect(() => {
		if (dataSearch?.length !== 0 || currentPage || distritoSelected) {
			async function getReservatorioList() {
				await api
					.get("/distritos", {
						params: {
							"populate[0]": "setor",
							"populate[1]": "setor.reservatorios",
							"populate[2]": "setor.reservatorios.reservatorio_img",
							"populate[3]": "setor.reservatorios.equipamento",
							"populate[4]": "setor.reservatorios.tipos_reservatorio",
							"populate[5]": "setor.reservatorios.tipo_material",
							"populate[6]": "setor.reservatorios.geometria",
							"populate[7]": "setor.reservatorios.setor",
							"populate[8]": "setor.reservatorios.setor.distritos",
							"populate[9]": "setor.reservatorios.equipamento.connection",
							"populate[10]": "setor.distritos",

							"filters[$and][0][setor][abastecimento][$eq]": true,
							"filters[$or][1][dmc_nome][$containsi]":
								(dataSearch?.length !== 0 &&
									dataSearch &&
									dataSearch.toLowerCase()) ||
								null,

							pagination: {
								page: currentPage,
								pageSize: 20,
							},
							sort: "dmc_nome:asc",
						},
					})
					.then((r) => {
						setDistritoList(r.data.data);
						setDistritoMeta(r.data.meta);

						// setLoader(false);
					})
					.catch((e) => console.error(e))
					.finally(() => setLoader(false));
			}
			setDistritoSelected(false);
			getReservatorioList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSearch, dataSearch.length, currentPage]);

	return (
		<Ctx.Provider
			value={{
				data: {
					dataSearch,
					setDataSearch,
					currentPage,
					setCurrentPage,
					distritoMeta,
					distritoList,
					loader,
					setLoader,
					setDistritoSelected,
					ReservatoriosAllList,
				},
			}}
		>
			{children}
		</Ctx.Provider>
	);
};

export default ReservatorioProvider;
ReservatorioProvider.propTypes = {
	children: P.node.isRequired,
};
