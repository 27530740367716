import P from "prop-types";
import { Card, CardBody } from "react-bootstrap/esm";
import { useNavigate } from "react-router-dom";

const ContentInitial = () => {
	const navigate = useNavigate();
	return (
		<Card
			style={{ marginTop: "128px" }}
			className={` animate__animated animate__faster border-0`}
		>
			<CardBody style={{ minHeight: "auto" }}>
				<div className={"texto"}>
					<h2 className="v1 ">
						TELEMETRIA <br />{" "}
						<strong>AUTARQUIA MAIS MODERNA E EFICIENTE</strong>
					</h2>
					<div className="card-text">
						<p>Um sistema de gestão de abastecimento inovador.</p>
						<p>
							Saiba como está o abastecimento de água no seu bairro. Mas
							lembre-se, a sua região pode ser abastecida por mais de um
							reservatório, já que eles são interligados para evitar
							interrupções momentâneas.{" "}
						</p>
					</div>

					<button
						className="botao btn bg-primary text-bg-primary"
						onClick={() => navigate("/search")}
					>
						Consultar Reservatórios <i className="fas fa-search"></i>
					</button>
				</div>
			</CardBody>
		</Card>
	);
};

export default ContentInitial;
ContentInitial.propTypes = {
	inputSearchShow: P.bool,
	setinputSearchShow: P.func,

	visible: P.bool,
};
