import { useEffect, useState } from "react";
import "animate.css";
import FormSearch from "./components/formSearch";
import ContentInitial from "./components/ContentInitial";
import ComponentImg from "./components/ComponentImg";
import TableResults from "./components/TableResults";
import "./assets/css/style.css";
import ReservatorioSelected from "./components/ReservatorioSelected";
import { Col, Container, Row } from "react-bootstrap";
import AuthProvider from "./store/providers/AuthProvider";
import ReservatorioProvider from "./store/providers/ReservatorioProvider";
import PageProvider from "./store/providers/PageProvider";
import { Routes, Route, Outlet, BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import routes from "./routes";
function App() {
	const [inputSearchShow, setinputSearchShow] = useState(false);
	const [visible, setVisible] = useState(false);
	const [visibleImg, setVisibleImg] = useState(false);
	const [formSearch, setFormSearch] = useState("");
	const [distritoSelected, setDistritoSelected] = useState([]);
	const [voltar, setVoltar] = useState(false);

	const [darkmode, setDarkMode] = useState(false);

	useEffect(() => {
		let bodyiframe = document.querySelector("body");
		let table = document.querySelectorAll("table");
		const receiveMessage = (event) => {
			if (event.data.type === "btn-contrast") {
				setDarkMode(event.data.darkMode);
				!event.data.darkMode
					? bodyiframe && bodyiframe.classList.add("dark-mode")
					: bodyiframe && bodyiframe.classList.remove("dark-mode");
				!event.data.darkMode
					? table && table.forEach((items) => items.classList.add("table-dark"))
					: table &&
					  table.forEach((items) => items.classList.remove("table-dark"));
				// Exibe no console.log
				console.log("Modo escuro ativado:", event.data.darkMode);
			}
		};

		// Adiciona um ouvinte de mensagem
		window.addEventListener("message", receiveMessage, false);

		return () => {
			// Remove o ouvinte de mensagem ao desmontar o componente
			window.removeEventListener("message", receiveMessage);
		};
	});

	useEffect(() => {
		if (voltar) {
			setVoltar(false);
			setDistritoSelected([]);
		}
	}, [voltar]);
	useEffect(() => {
		if (inputSearchShow === true) {
			setVisible(true);
			setVisibleImg(true);
		} else {
			setVisible(false);
			setVisibleImg(false);
		}
	}, [inputSearchShow]);

	useEffect(() => {
		if (!inputSearchShow) {
			setFormSearch("");
		}
	}, [inputSearchShow]);

	return (
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					{routes.map((route) => (
						<Route key={route.path} path={route.path} element={route.element} />
					))}
					{/* <Route path="/" element={<Layout />} />
					<Route path="/:slug/:params?/:value?" element={<Layout />} /> */}

					{/* <PageProvider>
					<Container className="mx-auto">
						<Row className="align-items-center align-items-md-start justify-content-md-start h-md-50 justify-content-center d-flex  ">
							<Col
								xs={12}
								md={12}
								lg={6}
								className="pt-md-0 pb-md-0 m-md-0 h-auto"
							>
								<ContentInitial
									inputSearchShow={inputSearchShow}
									visible={visible}
									setinputSearchShow={setinputSearchShow}
								/>{" "}
							</Col>
							<Col
								xs={12}
								md={12}
								lg={6}
								className="pt-md-0 pb-md-0 "
								style={{ height: "100%", maxWidth: "100%" }}
							>
								<ComponentImg formSearch={formSearch} visibleImg={visibleImg} />
							</Col>
						</Row>
						<ReservatorioProvider>
							<Row
								className="align-items-center"
								style={{
									display:
										distritoSelected.length === 0 && visible ? "block" : "none",
								}}
							>
								<Col>
									<FormSearch
										inputSearchShow={inputSearchShow}
										visible={visible}
										formSearch={formSearch}
										setFormSearch={setFormSearch}
										setinputSearchShow={setinputSearchShow}
									/>

									<TableResults
										formSearch={formSearch}
										hiddenImg={visible}
										setDistritoSelected={setDistritoSelected}
									/>
								</Col>
							</Row>

							<Row
								className="align-items-center animate__animated animate__fadeIn"
								style={{
									display: distritoSelected.length !== 0 ? "block" : "none",
									marginTop: 24,
								}}
							>
								<ReservatorioSelected
									darkmode={darkmode}
									distrito={distritoSelected}
									setVoltar={setVoltar}
								/>
							</Row>
						</ReservatorioProvider>
					</Container>
				</PageProvider> */}
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
