import P from "prop-types";
import { Ctx } from "../store/Context/Ctx";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Text } from "react-bootstrap";
const FormSearch = () => {
	const ctx = useContext(Ctx);
	const { setDataSearch, setLoader } = ctx.data;
	const navigate = useNavigate();

	const [search, setSearch] = useState("");
	const params = useParams();

	const normalizeString = (str) => {
		const prefixesToRemove = ["jardim", "jd", "jd.", "Jd.", "Jardim", "."];

		// Remover os prefixos especificados mantendo os espaços
		const normalized = prefixesToRemove.reduce((acc, prefix) => {
			const regex = new RegExp(`(^|\\s)${prefix}(\\s|$)`, "gi");
			return acc.replace(regex, " ");
		}, ` ${str}`); // Adiciona um espaço no início para não remover a primeira letra

		return normalized.trim().toLowerCase();
	};

	const normalizedSearch = normalizeString(search);

	const handleSubmit = (e) => {
		e.preventDefault();
		setDataSearch((normalizedSearch !== "" && normalizedSearch) || []);
	};
	return (
		<div className={` animate__animated animate__faster `}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: 12,
					marginBottom: 12,
				}}
			>
				<span className="v1 mb-3">
					<h4>Pesquisar</h4>
				</span>

				<button
					className="btn btn-outline-info"
					onClick={() => navigate("/")}
					type="button"
					title="Voltar para o inicio"
				>
					<i className="fa fa-arrow-rotate-left"></i> Voltar
				</button>
			</div>
			<div className="linha justify-content-between d-flex ">
				<div className="input-field d-flex mx-auto flex-column align-items-center justify-content-center col-8">
					<div className="col-12 mb-3 form-floating">
						<input
							type="search"
							id="search"
							list="datalistOptions"
							className="form-control "
							value={search}
							placeholder="Informe o nome do Bairro!"
							onChange={(e) => {
								setSearch(e.target.value);
								setLoader(true);
								setDataSearch(
									(normalizedSearch !== "" && normalizedSearch) || []
								);
							}}
						/>
						<label htmlFor="search">Informe o nome do Bairro!</label>
					</div>
					<div className="col-12 mb-3 ">
						<button
							className="btn  bg-primary"
							onClick={() => navigate("/reservatorios")}
							type="button"
						>
							<span className=" "></span>
							Ou clique aqui para ver Todos os Reservatórios{" "}
							<i className="fa fa-search"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormSearch;
FormSearch.propTypes = {
	inputSearchShow: P.bool,
	setinputSearchShow: P.func,
	setFormSearch: P.func,
	formSearch: P.string,
	visible: P.bool,
};
