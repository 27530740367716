import P from "prop-types";
import React, { useContext, useEffect, useState } from "react";
// import { api } from "../module/api";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PaginationComponent from "./PaginationComponent";
import { Ctx } from "../store/Context/Ctx";
const TableResults = () => {
	// const [Distritos, setDistritos] = useState([]);
	// const [dataSearch, setDataSearch] = useState([]);
	// const [distritoMeta, setDistritoMeta] = useState([]);

	const ctx = useContext(Ctx);
	const {
		distritoList,
		distritoMeta,
		currentPage,
		setCurrentPage,
		setDistritoSelected,
	} = ctx.data;
	const [loader, setLoader] = useState(false);
	const [darkmode, setDarkmode] = useState();
	const itemsPerPage = distritoMeta?.pagination?.pageSize; // ou o número desejado de itens por página
	const totalItems = distritoMeta?.pagination?.total;
	const storangeDark = localStorage.getItem("darkmode");
	const navigate = useNavigate();
	useEffect(() => {
		if (storangeDark === true) {
			setDarkmode(true);
		} else {
			setDarkmode(false);
		}
	}, [storangeDark]);
	useEffect(() => {
		setTimeout(() => {
			if (loader) {
				setLoader(false);
			}
		}, 700);
	}, [loader]);

	if (distritoList !== undefined && distritoList?.length === 0) {
		return false;
	}
	return (
		<div className={`texto animate__animated animate__faster mb-5`}>
			<span className="v1">
				RESULTADOS
				<br />
				<strong>Bairros encontrados!</strong>
			</span>
			{(!loader && (
				<div className="table-responsive">
					<table
						className={`table table-striped table-hover text-nowrap animate__animated animate__fadeIn ${
							darkmode ? "dark-mode" : ""
						}`}
					>
						<thead>
							<tr>
								<th scope="col">Bairro</th>
								<th scope="col"> Setor</th>
								<th scope="col" style={{ textAlign: "center" }}>
									Reservatorios
								</th>
							</tr>
						</thead>
						<tbody>
							{(loader === false &&
								distritoList?.length !== 0 &&
								distritoList?.map((distrito) => {
									return (
										<tr
											key={distrito.id}
											className="hover"
											onClick={() =>
												navigate(
													`/setor/id/${distrito.attributes?.setor?.data?.id}`
												)
											}
										>
											<td scope="row">{distrito?.attributes.dmc_nome}</td>
											<td>
												{
													distrito?.attributes.setor?.data?.attributes
														.setor_nome
												}
											</td>
											<td style={{ textAlign: "center" }}>
												{
													distrito?.attributes.setor?.data?.attributes
														.reservatorios?.data?.length
												}
											</td>
										</tr>
									);
								})) ||
								(!loader === false && distritoList?.length === 0 && (
									<tr>
										<td colSpan={3}>Nenhum bairro encontrado</td>
									</tr>
								)) ||
								(loader && (
									<tr>
										<td colSpan={3} style={{ textAlign: "center" }}>
											<i className="fa fa-circle-notch spin primary-color "></i>
											<br />
											<span>Carregando...</span>
										</td>
									</tr>
								))}
						</tbody>
					</table>
					<div className="paginacao">
						{" "}
						{totalItems !== 0 && itemsPerPage && (
							<PaginationComponent
								itemsCount={totalItems}
								itemsPerPage={itemsPerPage}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								alwaysShown={false}
							/>
						)}
					</div>
				</div>
			)) || (
				<div
					style={{
						width: "100%",
						height: "50vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<i className="fa fa-circle-notch spin primary-color "></i>
					<br />
					<span>Carregando...</span>
				</div>
			)}
		</div>
	);
};

export default TableResults;
TableResults.propTypes = {
	formSearch: P.oneOfType([P.bool, P.string]),
	hiddenImg: P.bool,
	setDistritoSelected: P.func,
};
