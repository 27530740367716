import P from "prop-types";
import Ecometria from "./ecometria";
const ComponentImg = () => {
	return (
		<div
			className={`item  animate__animated  animate__faster my-auto mx-auto text-center`}
			style={{
				minHeight: "80%",
				width: "100%",
				position: "relative",
			}}
		>
			<img
				src="https://saemas.com.br/uploads/pagina/elemento/campo/2023/05/gAfI05JSqXXKR2Kr/img-1.png"
				alt="SAEMAS"
				className="img-fluid"
				style={{ maxHeight: "100%", height: "auto" }}
			/>
		</div>
	);
};

export default ComponentImg;
ComponentImg.propTypes = {
	formSearch: P.oneOfType([P.bool, P.string]),
	visibleImg: P.bool,
};
