/* eslint-disable no-mixed-spaces-and-tabs */
import P from "prop-types";
import { useSocket } from "../../../hooks/useSocket";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import reservatorioImg from "../../../assets/img/reservatorio.jpg";
import { Image } from "antd";
import { GetHistoricos } from "./util/getHistoricos";
import { Liquid } from "@ant-design/plots";

import "../../../assets/css/style.css";
import Cookies from "js-cookie";
const ReservatorioCard = ({ reservatorio }) => {
	const [token, settoken] = useState(undefined);
	const { socketInstance, isConnected } = useSocket();
	const [dataSocket, setDataSocket] = useState([]);
	const [nivel_m, setNivel_m] = useState([]);
	const [nivel_p, setNivel_p] = useState([]);
	const [volume, setVolume] = useState([]);
	const [reservatorioData, setReservatorioData] = useState([]);

	useEffect(() => {
		if (token === undefined) {
			const t = Cookies.get("io");
			settoken(t);
		}
	}, [token]);

	useEffect(() => {
		if (reservatorio) {
			setReservatorioData(reservatorio);
		}
	}, [reservatorio]);

	const imgReservatorio =
		reservatorio &&
		reservatorio.attributes.reservatorio_img?.data?.attributes?.url
			? process.env.REACT_APP_API_URL +
			  reservatorio.attributes.reservatorio_img?.data?.attributes?.url
			: reservatorioImg; // Substitua pela URL da imagem padrão

	const [config, setConfig] = useState({
		percent: 0,
		style: {
			height: "100%",
		},
	});
	/* socketIo */
	useEffect(() => {
		if (
			isConnected &&
			reservatorio?.attributes?.equipamento?.data?.attributes?.topic_id
		) {
			const uniqueMessages = new Set();
			const handleMqttMessage = (data) => {
				const parsedData = JSON.parse(data.message);
				const messageString = data.message.toString();

				if (!uniqueMessages.has(messageString)) {
					uniqueMessages.add(messageString);
					setDataSocket([parsedData]);
				}
			};
			socketInstance.on("mqttMessage", handleMqttMessage);
			socketInstance.emit("mqttTopic", {
				topic:
					reservatorio?.attributes?.equipamento?.data?.attributes?.topic_id,
			});
		}
	}, [
		isConnected,
		socketInstance,
		reservatorio?.attributes?.equipamento?.data?.attributes?.topic_id,
	]);

	useEffect(() => {
		if (dataSocket.length !== 0) {
			setConfig({
				percent: parseFloat(dataSocket[0].nivel_p).toFixed(2) / 100,
			});
		}

		if (
			nivel_p.length !== 0 ||
			(nivel_p !== undefined && token !== undefined)
		) {
			setConfig({
				percent:
					nivel_p.length !== 0 &&
					parseFloat(nivel_p[0]?.pointValue).toFixed(2) / 100,
			});
		}
	}, [dataSocket, nivel_p, token]);

	/* Historicos */

	async function getHistoricoReservatorio(tag_id, setResult, token) {
		await GetHistoricos({
			limit: 1,
			order: "ts:desc",
			tag_id,
			token,
			equipamentos: reservatorioData?.attributes?.equipamento,
			table: "pointValues",
			dbname:
				reservatorioData?.attributes?.equipamento?.data?.attributes?.connection
					?.data?.attributes?.dbname,
			connection:
				reservatorioData?.attributes?.equipamento?.data?.attributes?.connection
					?.data,
		})
			.then((r) => {
				setResult(r);
			})
			.catch((e) => console.error(e));
	}
	useEffect(() => {
		if (
			nivel_m.length === 0 &&
			reservatorioData?.length !== 0 &&
			token !== undefined
		) {
			getHistoricoReservatorio(
				reservatorioData?.attributes?.nivel_m_tagid,
				setNivel_m,
				token
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservatorioData, token]);

	useEffect(() => {
		if (nivel_p.length === 0 && reservatorio && token) {
			async function getHistoricoReservatorio() {
				await GetHistoricos({
					limit: 1,
					order: "ts:desc",
					token,
					tag_id: reservatorio?.attributes?.nivel_percent_tagid,
					equipamentos: reservatorio?.attributes?.equipamento,
					table: "pointValues",
					dbname:
						reservatorio?.attributes?.equipamento?.data?.attributes?.connection
							?.data?.attributes?.dbname,
					connection:
						reservatorio?.attributes?.equipamento?.data?.attributes?.connection
							?.data,
				})
					.then((r) => {
						setNivel_p(r);
					})
					.catch((e) => console.error(e));
			}
			getHistoricoReservatorio();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservatorio?.length, token]);

	useEffect(() => {
		if (volume.length === 0 && reservatorioData && token !== undefined) {
			getHistoricoReservatorio(
				reservatorioData?.attributes?.volume_tagid,
				setVolume,
				token
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservatorioData]);

	if (reservatorioData.length === 0) {
		return false;
	}
	return (
		<Row style={{ height: "auto" }} className="mb-5 mt-4 ">
			<Col md={12} className="text-center">
				<h4 style={{ fontWeight: 300 }}>{reservatorioData.attributes.nome}</h4>
			</Col>

			<Col
				lg={4}
				sm={12}
				md={12}
				xs={12}
				style={{
					height: "300px",
					padding: 4,
					backgroundColor: "#0000008f",
					backgroundImage: `url(${imgReservatorio})`,
					backgroundPositionX: "center",
					backgroundPositionY: "center",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundBlendMode: "color-burn",
					textAlign: "center",
				}}
			>
				<Image src={imgReservatorio} height={"100%"} />
				{/* <img src={imgReservatorio} height={"100%"} alt="Reservatório" /> */}
			</Col>

			<Col lg={4} sm={6} md={6} xs={12} style={{ height: "300px" }}>
				{config && <Liquid shape="rect" {...config} />}
			</Col>
			<Col
				sm={4}
				md={4}
				xs={12}
				style={{ height: "300px" }}
				className="text-center d-flex justify-content-center align-items-center"
			>
				<div className="table-responsive " style={{ width: "100%" }}>
					<table
						className={`table table-striped table-hover text-nowrap animate__animated animate__fadeIn `}
					>
						<thead>
							<tr>
								<th colSpan={2}>Indicadores</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<span style={{ fontSize: "smaller" }} className="text-wrap">
										{" "}
										Atualizado em{" "}
										{(dataSocket[0] &&
											new Date(dataSocket[0]?.ts).toLocaleString()) ||
											(nivel_p.length !== 0 &&
												new Date(nivel_p[0]?.ts).toLocaleString())}{" "}
									</span>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>Nivel</th>
								<td>
									<span>
										{(dataSocket[0] &&
											parseFloat(dataSocket[0]?.nivel_p).toFixed(1)) ||
											(nivel_p.length !== 0 &&
												parseFloat(nivel_p[0]?.pointValue).toFixed(1))}{" "}
										{"(%)"}
									</span>
								</td>
							</tr>
							<tr>
								<th>Altura</th>

								<td>
									<span>
										{(dataSocket[0] &&
											parseFloat(dataSocket[0]?.nivel_m).toFixed(1)) ||
											(nivel_m.length !== 0 &&
												parseFloat(nivel_m[0]?.pointValue).toFixed(1))}{" "}
										{"(m)"}
									</span>
								</td>
							</tr>
							<tr>
								<th>Volume</th>
								<td>
									<span>
										{(dataSocket[0] && parseInt(dataSocket[0]?.volume)) ||
											(volume.length !== 0 &&
												parseFloat(volume[0]?.pointValue).toFixed(1))}
									</span>{" "}
									{"(m³)"}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Col>
		</Row>
	);
};

export default ReservatorioCard;
ReservatorioCard.propTypes = {
	reservatorio: P.object,
	darkmode: P.bool,
};
